@import "_colors";

@mixin bebas-neue {
    font-family: 'Bebas Neue', sans-serif;
}

@mixin inter {
    font-family: 'Inter', sans-serif;
}

@mixin h1 {
    font-size: 3.5rem;
    line-height: 1em;
}

@mixin h2 {
    font-size: 2.5rem;
    line-height: 1em;
}

@mixin h3 {
    font-size: 2rem;
    line-height: 1em;
}

@mixin h4 {
    // p.big
    font-size: 1.5rem;
    line-height: 1em;
}

@mixin h5 {
    // p.big
    font-size: 1.25rem;
    line-height: 1em;
}

@mixin p {
    font-size: 1rem; // 1.25rem

}

@mixin smallp {
    font-size: 0.875rem;
}

@mixin section {
    padding: 7rem 0rem;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
}

@mixin layout {
    width: 100%;
    max-width: 1024px; //get from variable
    padding: 0 2rem; //get from variable
}



@mixin formLabel {
    font-size: 1rem;
    font-weight: 500;
    color: $stb-black;

    >.required {
        color: red;
        font-size: 0.75rem;
        text-transform: uppercase;
        margin-left: 0.25rem;
    }
}


@mixin formControl {
    font-size: 1rem;
    font-weight: 400;
    background: $stb-white;
    height: 3rem;
    border-radius: 0.5rem;

    &:focus {
        color: $stb-black;
        border-color: $stb-primary-dark;
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba($stb-primary-light, 0.25);

    }
}

@mixin formCheck {
    min-height: 3rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0;

    input {
        font-size: 1rem;
        color: $stb-black;
        width: 1.25rem;
        height: 1.25rem;
        padding: 0;
        border: 1px solid $stb-black;
        background: $stb-white;
        box-shadow: none;
        margin: 0 1rem 0 0;
        position: relative;
    }
    input[type="radio"]:checked
    input[type="checkbox"]:checked{
        accent-color: $stb-secondary-light;
    }

    label {
        font-size: 1rem;
        font-weight: 400;
        color: $stb-black;
    }

}