div.main {
    background-color: #fff; // get from variable
    display: flex;
    flex-direction: column;
    z-index: 1;
    //overflow-x: hidden;

    &.mobileMenuOpen {
        overflow: hidden;
    }

    .content {
        margin-top: 5rem;
    }
}

