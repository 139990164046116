@use "../../../styles/mixins";
@import "../../../styles/colors";

ul.level1 {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem 1rem;

    >li {
        display: flex;
        flex-direction: column;

        >header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0.5rem 0;

            >a, >p {
                text-transform: uppercase;
                font-size: 0.875rem;
                font-weight: 600;

                &:hover {
                    cursor: pointer;
                    color: $stb-primary-dark;
                }
            }

            .chevron {
                cursor: pointer;
            }
        }
    }
}

ul.level2 {

    >li {

        >a {
            text-transform: uppercase;
            font-size: 0.75rem;
        }
    }
}

@media screen and (max-width: 1024px) {
    ul.level1 {
        grid-template-columns: repeat(3, 1fr);
        //padding-left: 0.5rem;
    }
}

@media screen and (max-width: 576px) {

    ul.level1 {
        grid-template-columns: repeat(2, 1fr);
    }

}

@media screen and (max-width: 375px) {

    ul.level1 {
        grid-template-columns: 1fr;
    }

}