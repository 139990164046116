@use "../../styles/mixins";
@import "../../styles/colors";

.filter {
    margin-bottom: 2rem;

    .filterTitle {
        font-weight: 600;
        font-size: 1rem;
        margin-bottom: 0.5rem;
    }

    .rangeForm {
        max-width: 100%;

        margin: 0 auto;

        .formControl {
            @include mixins.formControl;
            width: 4.5rem;
            height: 2rem;
            font-size: 0.75rem;
        }

        .formControlWithError {
            border-color: red;
            box-shadow: inset 0 0 0.25rem 0rem rgba(255, 0, 0, 0.25);

        }

        .separator {
            height: 2rem;
            font-size: 0.75rem;
            font-weight: 400;
            color: $stb-gray-dark;
            margin: 0 0.25rem;
            line-height: 2rem;
        }

        .submitControl {
            margin-left: 0.5rem;
        }

        .submitIcon {
            height: 1.5rem;
        }


    }




}