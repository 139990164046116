@use "../../styles/mixins";
@import "../../styles/colors";

@mixin navButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 4rem; //get from variable
    height: 4rem; //get from variable
    border: none;
    background-color: transparent;
    color: $stb-black;
}

@mixin navOption {
    font-family: "Inter", sans-serif;
    text-decoration: none;
    font-size: 1rem;
    font-weight: 400;
    text-transform: uppercase;
}


.header {
    background-color: #fff; // get from variable
    min-height: 5rem; // get from variable
    max-height: 100vh;
    display: grid;
    padding: 0 1rem;
    grid-template-areas:
        "logo dynamic fixed"
        "store store store"
        "user user user";
    grid-template-columns: 14rem auto min-content;
    grid-template-rows: 5rem;
    position: fixed;
    width: 100%;
    top: 0;
    box-shadow: 0 0 2rem 0.25rem rgba(0, 0, 0, 0.2);
    z-index: 100;

    @supports (backdrop-filter: blur(0.5rem)) {
        backdrop-filter: blur(0.5rem) saturate(125%);
        background-color: rgba(255, 255, 255, 0.75);
    }

    .headerBrand {
        grid-area: logo;
        align-self: center;
        justify-self: left;
    }

    .dynamicNav,
    .fixedNav {
        display: flex;
        height: 4rem; // get from variable

        >ul {
            display: flex;
            list-style: none;
            padding: 0;
            height: 100%;
            align-items: stretch;

            >li {
                display: flex;
                align-items: stretch;
                justify-content: center;
                padding: 0 1rem;

                &:last-child {
                    padding-right: 0;
                }
            }
        }

    }

    .dynamicNav {
        grid-area: dynamic;
        align-self: center;
        justify-self: right;
        transition: 0.6s all;
    }

    .fixedNav {
        grid-area: fixed;
        align-self: center;
        justify-self: right;
    }

    .searchNav {
        grid-row-start: dynamic;
        grid-column-start: dynamic;
        grid-row-end: fixed;
        grid-column-end: fixed;
        align-self: center;
        justify-self: stretch;
        display: flex;
        margin-left: 2rem;
    }

    .megaMenu {
        padding: 2rem 1rem 3rem;
        width: 100%;
        border-top: thin solid rgba(0, 0, 0, 0.25);
        max-height: calc(100vh - 5rem);
        transition: opacity 0.6s, height 0.6s, padding 0.6s, margin 0.6s, border-top 3s;

        &.closed {
            opacity: 0;
            visibility: hidden;
            border-top: 0 solid rgba(0, 0, 0, 0.25);
            height: 0;
            padding: 0 1rem;
            margin: 0;
        }
    }

    .storeMenu {
        grid-area: store;
        align-self: center;
        justify-self: center;
        max-height: 100vh;
        overflow: auto;
    }

    .userMenu {
        grid-area: store;
        align-self: center;
        justify-self: center;
        max-height: 100vh;
        overflow: auto;
    }

    .userAccountItem {
        @include navButton;

        &:hover {
            color: $stb-primary-dark;
        }
    }

}

.headerOverlay {
    background: rgba(255, 255, 255, 0.4);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 0;
    z-index: 99;
    //backdrop-filter: saturate(0);
    opacity: 0;
    transition: 0.6s opacity, 0.6s box-shadow, 0s height;
    box-shadow: inset 0 0 3rem 0 rgba(0, 0, 0, 0.5);

    &.open {
        height: 100%;
        opacity: 1;
    }
}





@media screen and (min-width: 1025px) {
    .menuButton {
        display: none !important;
    }
}

@media screen and (max-width: 1024px) {
    .header {
        grid-template-areas:
            "logo fixed"
            "dynamic dynamic"
            "store store"
            "user user";
            
        grid-template-rows: 5rem;
        grid-template-columns: min-content auto;

        &.open {
            //height: 100vh;
            padding-bottom: 2rem;
            grid-template-rows: 5rem max-content minmax(auto,max-content) max-content;
        }

        .searchNav {
            grid-row-start: fixed;
            grid-column-start: fixed;
            grid-row-end: fixed;
            grid-column-end: fixed;
        }

        .dynamicNav,
        .fixedNav {
            &.closed {
                height: 0;
                visibility: hidden;
                opacity: 0;
                padding: 0;
                margin: 0;
            }
        }

        .dynamicNav {
            height: max-content;
            width: 100%;
            padding: 1rem 0.5rem;
            align-items: stretch;
            flex-direction: column;
            border-top: thin solid rgba(0, 0, 0, 0.25);
    
            >ul {
                flex-direction: column;
                align-items: stretch;
    
                >li {
                    padding: 0;
                    display: flex;
                    justify-items: stretch;
                    margin: 0;
    
                    >a,
                    >button {
                        width: 100%;
                        height: 2rem;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        justify-items: center;
                        padding: 0;
                    }
    
                }
            }
    
        }
        .megaMenu {
            padding-bottom: 1rem;
        }

        .storeMenu {
            height: max-content;
            max-height: 22rem;
            overflow: auto;
            padding: 1rem;
        }
        .userMenu {
            grid-area: user;
            padding: 1rem 0.5rem;
        }
    
        .userAccountItem {
            display: none !important;
        }
        
    }

    

}

@media screen and (max-width: 640px) {
    .header {
        grid-template-columns: 4rem auto;
    }
}
// .divider {

//     &.divider1 {
//         order: 3;
//         display: block;
//     }

//     &.divider2 {
//         order: 7;
//         display: block;
//     }
// }