@use "../../styles/mixins";
@import "../../styles/colors";

.section {
    @include mixins.section;
    background: $stb-bg-light;
    padding: 2rem 2rem 0;
    flex-direction: column;

    .layout {
        position: relative;
        width: 100%;
        max-width: 75rem;
        margin: 0 auto 4rem;
        display: grid;
        grid-template-columns: minmax(15rem, 25rem) auto;
        column-gap: 1rem;
        row-gap: 1rem;
        grid-template-areas:
            "nav nav"
            "aside results";

        >nav {
            grid-area: nav;

        }

        >aside {
            width: 100%;
            flex-shrink: 0;
            flex-grow: 0;
            grid-area: aside;

            >header {
                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                position: relative;
                margin-bottom: 2rem;

                h1 {
                    @include mixins.bebas-neue;
                    @include mixins.h1;
                    color: $stb-primary-light;
                }

                p {
                    font-size: 1.25rem;
                    line-height: 1.5em;
                }

            }

            .filters {
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                >header {
                    display: none;

                    h2 {}
                }

                .appliedFilters {
                    display: flex;
                    align-items: flex-start;
                    flex-wrap: wrap;
                    margin-bottom: 2rem;

                    &:empty {
                        margin-bottom: 0;
                    }
                }
            }
        }

        .results {
            flex: 3;
            display: flex;
            flex-direction: column;
            align-items: center;

            grid-area: results;
        }

        .productsList {
            width: 100%;
            padding: 1rem 0;
            background-color: $stb-white;
            border-radius: 1rem;

            >li {
                width: 100%;

                &:not(:last-child) {
                    border-bottom: thin solid $stb-gray-light;
                }
            }
        }

        

    }
}

@media screen and (max-width: 980px) {
    .section {
        .layout {
            grid-template-columns: 1fr;
            grid-template-areas:
                "nav"
                "aside"
                "results";

            >aside {
                .filters {
                    //background-color: $stb-white;
                    //border-radius: 1rem;
                    border-bottom: thin solid $stb-gray-light;
                    margin-bottom: 1rem;

                    >header {
                        border-bottom: thin solid $stb-gray-light;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        width: 100%;
                        padding: 1rem 0rem;
                        cursor: pointer;

                        h2 {
                            font-size: 1rem;
                            text-transform: uppercase;
                            margin: 0;
                            font-weight: 400;
                        }
                    }

                    >article {
                        padding: 1rem;
                        transition: 0.6s all;
                    }

                    &.closed {
                        >header {
                            border: none;
                        }
                        >article {
                            visibility: hidden;
                            opacity: 0;
                            height: 0;
                            padding: 0;
                        }
                    }

                }
            }


        }
    }
}