@use "../../styles/mixins";
@import "../../styles/colors";

.stbButton {
    background-color: $stb-primary-light;
    border: none;
    border-radius: 0.5rem;
    padding: 1rem 2rem;
    height: 3rem;
    line-height: 3rem;
    color: $stb-white;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    cursor: pointer;
    transition: 0.3s all;
    white-space: nowrap;
    font-weight: 600;

    &:disabled {
        background-color: $stb-gray-light;
        color: $stb-gray-light;
        cursor: not-allowed;
    }

    >.leftIcon,
    >.rightIcon {
        width: 1.25rem;
        height: 1.25rem;
        display: flex;
    }

    >.leftIcon {
        margin-right: 0.5rem;
    }

    >.rightIcon {
        margin-left: 0.5rem;
    }

    &:hover {
        background-color: darken($color: $stb-primary-light, $amount: 10);
    }

    &.outline {
        background-color: transparent;
        border: 1px solid $stb-primary-dark;
        color: $stb-primary-dark;

        &:hover {
            border-color: darken($color: $stb-primary-dark, $amount: 10);
            color: darken($color: $stb-primary-dark, $amount: 10);
        }

        &:disabled {
            background-color: transparent;
            border: 1px solid $stb-gray-light;
            color: $stb-gray-light;
            cursor: not-allowed;
        }
    }

    &.secondary {
        background-color: $stb-secondary-light;
        color: $stb-white;

        &:hover {
            background-color: $stb-secondary-dark;
        }


        &.outline {
            background-color: transparent;
            border: 1px solid $stb-secondary-light;
            color: $stb-secondary-light;

            &:hover {
                border-color: $stb-secondary-dark;
                color: $stb-secondary-dark;
            }
        }
    }

    &.small {
        font-size: 0.75rem;
        padding: 0.5rem 1rem;
        height: 2rem;

        >.leftIcon,
        >.rightIcon {
            width: 1rem;
            height: 1rem;
        }

        >.leftIcon {
            margin-right: 0.25rem;
        }

        >.rightIcon {
            margin-left: 0.25rem;
        }
    }

    &.large {
        font-size: 1.25rem;
        height: 3.5rem;

        >.leftIcon,
        >.rightIcon {
            width: 1.5rem;
            height: 1.5rem;
        }

        >.leftIcon {
            margin-right: 0.5rem;
        }

        >.rightIcon {
            margin-left: 0.5rem;
        }
    }
}