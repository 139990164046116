//@use "../../styles/mixins";
//@import "../../styles/colors";

.menuContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem 1rem;
    padding-top: 0.5rem;
}

.userHeader {
    //grid-column: 1;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    h1 {
        margin: 0;
        font-size: 1.25rem;
        line-height: 1.5rem;
        margin-bottom: 0.5rem;
        font-weight: 600;
        margin: 0 0 0.5rem 0;
    }

    p {
        font-size: 1rem;
        opacity: 0.5rem;
        margin: 0 0 1rem 0;
    }
}

ul.options {
    //grid-column: 2;

    >li {
        display: flex;
        flex-direction: column;

        >a,
        >button {
            padding: 0.5rem 0;
            text-transform: uppercase;
            font-size: 0.875rem;
            font-weight: 600;
        }

        >button {
            border: none;
            padding: 0;
            text-align: left;
            background: transparent;
        }
    }
}


@media screen and (max-width: 576px) {

    .menuContainer {
        grid-template-columns: repeat(1, 1fr);
    }
}
