@import "../../../styles/colors";

.button {
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;

    .icon {
        position: relative;
        width: 1.25rem;
        height: 1.25rem;
    }

    .bar1,
    .bar2,
    .bar3 {
        width: 1.25rem;
        height: 0.125rem;
        background-color: $stb-black;
        transition: 0.4s;
        position: absolute;
        border-radius: 0.125rem;
    }

    .bar1 {
        transform-origin: center center;
        top: 0;
    }

    .bar2 {
        top: calc(50% - 0.0625rem);
    }

    .bar3 {
        bottom: 0;
        transform-origin: center center;
    }

    &:hover {
        .bar1,
        .bar2,
        .bar3 {
            background-color: $stb-primary-dark;
        }

    }

    &.active {
        .bar1,
        .bar2,
        .bar3 {
            border-radius: 0;
        }
        .bar1 {
            transform: rotate(-45deg);
            top: calc(50% - 0.0625rem);
        }

        .bar2 {
            opacity: 0;
        }

        .bar3 {
            transform: rotate(45deg);
            bottom: calc(50% - 0.0625rem);
        }
    }

}