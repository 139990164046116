@use "../../../styles/mixins";
@import "../../../styles/colors";

.searchToolbar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
}

.searchForm {
    flex: 1;

    .formGroup {
        width: calc(100% - 1rem);
        margin: 0;
    }
    .inputGroup {
        width: 100%;
        border: thin solid #ced4da;
        border-radius: 0.5rem;
    }
    .inputGroupAppend {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.25rem;

    }
    .formControl {
        @include mixins.formControl;
        border: none;
        &:focus {
            box-shadow: none;
        }   
    }
}
.closeSearchToolbar {
    flex-grow: 0;
    flex-shrink: 0;
}
