@import "../../../src/styles/colors";

.goBack {
    margin-bottom: 3rem;
    
    @media (min-width: 768px) {
        margin-bottom: 2rem;
    }

    span {
        color: $stb-primary-dark;

        &:hover {
            cursor: pointer;
        }
    }
}

.goBackIcon {
    width: 24px;
    color: $stb-primary-dark;
}