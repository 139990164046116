$stb-black: #1A1A1A;
$stb-gray: #666;
$stb-gray-dark: #666;
$stb-gray-light: #ddd;
$stb-white: #fff;

$stb-primary-light: #FBA819;
$stb-primary-dark: #F47621;

$stb-secondary-light: #6DBE45;
$stb-secondary-dark: #26A53B;

$stb-bg-light: #FFF9F5;
$stb-bg-dark: #FEF2DD;
