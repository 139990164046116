@use "../../styles/mixins";
@import "../../styles/colors";

.productItem {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 2rem;

    .picture {
        height: 14rem;
        width: 14rem;
        margin: 0 1rem 0 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-grow: 0;
        flex-shrink: 0;

        img {
            max-width: 100%;
            max-height: 100%;
        }
    }

    .info {
        flex-grow: 1;
        flex-shrink: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        header {
            width: 100%;
            display: flex;
            flex-direction: column;
            margin-bottom: 1rem;
            align-items: stretch;


            h1 {
                font-size: 1.5rem;
                font-weight: 600;
                line-height: 2;
                margin: 0 0 0.5rem 0;
                color: $stb-black;
            }

            >p {
                font-size: 1rem;
                line-height: 1.5;
                margin: 0 0 0.5rem 0;
                color: $stb-black;
            }
            .price {
            
                font-weight: bold;
                font-size: 1.25rem;
                margin: 0 0 0.5rem 0;
                color: $stb-primary-light;
            }
        }

        

        .actions {
            display: flex;
            flex-direction: row;

            > button {
                margin-right: 1rem;
            }
        }
    }



}

@media screen and (max-width: 640px) {
    .productItem {
padding: 1rem;
        .picture {
            height: 5rem;
            width: 5rem;
            
        }

        .info {
            header {
                margin-bottom: 0.5rem;
                h1 {
                    font-size: 1.25rem;
                    line-height: 1.25;
                }

                >p {
                    font-size: 0.75rem;
                }
                .price {
                    font-size: 1rem;
                }
            }
        }
    }
}