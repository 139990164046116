@use "../../styles/mixins";
.FooterBrand {
    display: flex;
    align-items: center;

    .logo {
        height: 4rem; // get from variable
        margin-right: 0.5rem;
        mix-blend-mode: multiply
    }
    h1 {
        @include mixins.bebas-neue;
        margin: 0;
        font-size: 3rem; // get from variable
        line-height: 1em; // get from variable
    }
}