@use "./styles/mixins";
@import "theme";

.bebas-neue {
  @include mixins.bebas-neue;
}

.inter {
  @include mixins.inter;
}

body {
  @include mixins.inter;
}
